import * as React from "react"
import { Link } from "gatsby"
import { StoreContext } from "../../context/store-context"
import { LineItem } from "../../components/line-item"
import { formatPrice } from "../../utils/format-price"
import { CheckoutButton } from "../../components/checkout-button"
import {
  table,
  totals,
  collapseColumn,
  grandTotal,
  labelColumn,
  productHeader,
} from "./cart.module.css"

export default function CartPage({ pageContext }) {
  const { i18n } = pageContext
  const { checkout, loading } = React.useContext(StoreContext)
  const emptyCart = checkout.lineItems.length === 0

  return (
    <>
      <div className="flex flex-col items-stretch my-9 out-wrapper">
        {emptyCart ? (
          <div className="flex flex-col items-center justify-center min-h-[50vh] text-center">
            <h1 className="mb-10 text-3xl font-bold">
              {i18n.cart.yourCartIsEmpty}
            </h1>
            <Link
              to={i18n.slugs.products}
              className="inline-block mt-8 text-2xl font-bold text-primary-600"
            >
              {i18n.cart.viewProducts}
            </Link>
          </div>
        ) : (
          <>
            <h1 className="py-10 mb-10 font-bold text-center">
              {i18n.cart.yourCart}
            </h1>
            <table className={table}>
              <thead>
                <tr>
                  <th className="fixed w-0 h-0 overflow-hidden">
                    {/* {i18n.cart.image} */}
                  </th>
                  <th className={productHeader}>{i18n.cart.product}</th>
                  <th className={collapseColumn}>{i18n.cart.price}</th>
                  <th>{i18n.cart.qty}</th>
                  <th className={[totals, collapseColumn].join(" ")}>Total</th>
                </tr>
              </thead>
              <tbody className="text-xl">
                {checkout.lineItems.map((item) => (
                  <LineItem item={item} key={item.id} i18n={i18n} />
                ))}
              </tbody>
            </table>
            <table className="w-full max-w-xs mt-12 ml-auto">
              <tbody>
                <tr>
                  <td className={labelColumn}>{i18n.cart.subTotal}</td>
                  <td className={totals}>
                    {formatPrice(
                      checkout.subtotalPriceV2.currencyCode,
                      checkout.subtotalPriceV2.amount
                    )}
                  </td>
                </tr>
                <tr>
                  <td className={labelColumn}>Taxes</td>
                  <td className={totals}>
                    {i18n.cart.calculAtCheckout}
                    {/* {formatPrice( */}
                    {/* checkout.totalTaxV2.currencyCode, */}
                    {/* checkout.totalTaxV2.amount */}
                    {/* )} */}
                  </td>
                </tr>
                <tr>
                  <td className={labelColumn}>{i18n.cart.shipping}</td>
                  <td className={totals}>{i18n.cart.calculAtCheckout}</td>
                </tr>
                <tr className={grandTotal}>
                  <td className={labelColumn}>{i18n.cart.totalPrice}</td>
                  <td className={totals}>
                    {formatPrice(
                      checkout.totalPriceV2.currencyCode,
                      checkout.totalPriceV2.amount
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
            <CheckoutButton
              loading={loading}
              checkout={checkout}
              i18n={i18n}
              className="my-16 px-8 py-3 text-3xl"
            />
          </>
        )}
      </div>
    </>
  )
}
